import { compose, withState, withProps } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
import makeCommandDispatcher from "web/core/apollo/makeCommandDispatcher";

const messages = defineMessages({
  error: {
    id: "modules.User.LoginForm.error.loginError",
    defaultMessage: "Incorrect username or password.",
  },
  accountTemporaryDisabled: {
    id: "modules.User.LoginForm.error.accountTemporaryDisabled",
    defaultMessage: "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
  }
});


// Mapping of errors using text content of errors returned by Magento.
const LOGIN_ERRORS_MAP = [
  {
    code: 'accountTemporaryDisabled',
    message: 'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.',
  },
];
const EnhanceLoginForm = ({ LoginMutation }) =>
  compose(
    injectIntl,
    withState("canSubmit", "setCanSubmit", false),
    withProps((props) => ({
      onSuccess: () => {
        props.onLoginSuccess();
      },
      onError: ({ errorMessage }) => {
        const errorCode = LOGIN_ERRORS_MAP.find(({ message }) => message === errorMessage)?.code
          || 'error';
        props?.setLoginError(props.intl.formatMessage(messages[errorCode]));
      },
    })),
    makeCommandDispatcher({
      authenticateUser:
        (props) =>
        ({ email, password }) => {
          props?.setLoginError && props.setLoginError(null);
          return {
            options: {
              mutation: LoginMutation,
              refetchQueries: ["User", "Cart", "CartQty"],
              variables: {
                username: email,
                password: password,
              },
            },
          };
        },
    })
  );

export default EnhanceLoginForm;
